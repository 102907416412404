<template>
  <v-list-item-content>
    <v-list-item-title v-text="item.text"></v-list-item-title>
    <v-list-item-subtitle v-text="item.commune"></v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
export default {
  props: {
    item: { type: Object, default: () => {} },
  },
};
</script>
