import {
  required,
  maxLength,
  minLength,
  email,
  numeric,
} from "vuelidate/lib/validators";
import validationThings from "../validation-things";
import CrmCustomersService from "@/services/CrmCustomersService";
import { EMAIL, MOBILEPHONE } from "@/models/channelTypes.js";

export default {
  mixins: [validationThings],
  data() {
    return {
      validatedProperty: "contact",
      customValidationDefinition: [
        {
          name: "phones",
          definition: _arrayCustomValidationDefinition,
        },
        {
          name: "emails",
          definition: _arrayCustomValidationDefinition,
        },
      ],
    };
  },
  validations: {
    contact: {
      firstname: {
        required,
        maxLength: maxLength(50),
      },
      surname: {
        required,
        maxLength: maxLength(50),
      },
      type: {
        required,
      },
      emails: {
        isEmailRequired,
        isUniqueFirstEmail,
        $each: {
          text: {
            email,
            sameMail: IsUniqueInEmailsCollection,
          },
        },
      },
      phones: {
        isUniqueFirstPhone,
        $each: {
          text: {
            onlyNumericOrPlus,
            minLength: minLength(9),
            samePhone: IsUniqueInPhonesCollection,
          },
        },
      },
    },
  },
};

function onlyNumericOrPlus(value) {
  if (value && numeric(value.substring(1))) {
    if (value[0] == "+" || !isNaN(value[0])) return true;
  }
  return false;
}

function IsUniqueInEmailsCollection(value) {
  const emails = this.contact.emails.map((e) => e.text);
  const duplicate = emails.filter((item) => {
    if (emails.indexOf(item) !== emails.lastIndexOf(item) && item === value) {
      return true;
    }
  });
  return duplicate.length === 0;
}

function isEmailRequired(value) {
  if (!Array.isArray(value) || value.length === 0 || !this.contact) {
    return true;
  }

  const isRequired = this.contact.isConnectedWithUser;
  return isRequired ? value[0] && value[0].text : true;
}

function IsUniqueInPhonesCollection(value) {
  const phones = this.contact.phones.map((e) => e.text);
  const duplicate = phones.filter((phone) => {
    if (
      phones.indexOf(phone) !== phones.lastIndexOf(phone) &&
      phone === value
    ) {
      return true;
    }
  });
  return duplicate.length === 0;
}

async function isUniqueFirstEmail(value) {
  if (
    !Array.isArray(value) ||
    value.length === 0 ||
    !value[0] ||
    !value[0].text ||
    !email(value[0].text)
  ) {
    return true;
  }
  return !(await CrmCustomersService.CheckIsMediumTaken(
    value[0].text,
    EMAIL,
    this.contact.id
  ));
}

async function isUniqueFirstPhone(value) {
  if (!Array.isArray(value) || value.length === 0) return true;
  if (value[0].text.length < 9) return true;
  return !(await CrmCustomersService.CheckIsMediumTaken(
    value[0].text,
    MOBILEPHONE,
    this.contact.id
  ));
}

function _arrayCustomValidationDefinition(param, rulesText) {
  let resultArray = [];

  Object.keys(param.$each.$iter).forEach((el, index) => {
    let elParams = param.$each[el].text.$params;
    let elResultArray = [];

    Object.keys(elParams).forEach((key) => {
      if (!param.$each[el].text[key]) {
        elResultArray.push(rulesText[key]);
      }
    });

    if (index === 0) {
      if (param.isUniqueFirstEmail === false) {
        elResultArray.push(rulesText.isUniqueFirstEmail);
      } else if (param.isUniqueFirstPhone === false) {
        elResultArray.push(rulesText.isUniqueFirstPhone);
      } else if (param.isEmailRequired === false) {
        elResultArray.push(rulesText.isEmailRequired);
      }
    }

    resultArray.push(elResultArray);
  });

  return resultArray;
}
