<template>
  <TwoSides>
    <!-- LEFT SIDE -->
    <template #left>
      <div class="field-content">
        <!-- IMIĘ I NAZWISKO -->
        <div class="fields-section">
          <div class="field">
            <BaseInput
              required
              :name="$t('newContact.firstname')"
              :value="contact.firstname"
              :error-messages="validationErrors.firstname"
              @input="(value) => onValueChange('firstname', value)"
            />
          </div>

          <div class="field">
            <BaseInput
              required
              :name="$t('newContact.surname')"
              :value="contact.surname"
              :error-messages="validationErrors.surname"
              @input="(value) => onValueChange('surname', value)"
            />
          </div>
        </div>

        <!-- TYP -->
        <div class="fields-section">
          <div class="field">
            <BaseSelect
              required
              item-text="text"
              item-value="id"
              :name="$t('global.type')"
              :items="typeItems"
              :value="contact.type"
              :error-messages="validationErrors.type"
              @input="(value) => onValueChange('type', value)"
            />
          </div>
        </div>

        <!-- EMAIL I TELEFON -->
        <div class="fields-section">
          <div class="field">
            <CustomList
              favaritePropertyName="isPreferred"
              :setFavoriteTooltipText="$t('contacts.setAsPreferredChannelType')"
              :unsetFavoriteTooltipText="
                $t('contacts.unsetAsPreferredChannelType')
              "
              :displayFavoriteButtonWhenNoEdit="true"
              :edit="true"
              :displayFavoriteButton="true"
              :label="$t('common.email')"
              :additionalLabel="$t('common.additionalEmail')"
              :newItem="newMailChannel"
              :values="contact.emails"
              :error-messages="validationErrors.emails"
              @input="onEmailChange"
              @setAsFavorite="
                (index) => onSetEmailPreferredChannel(index, true)
              "
              @unsetAsFavorite="
                (index) => onSetEmailPreferredChannel(index, false)
              "
            />
          </div>
          <div class="field">
            <CustomList
              mask="###-###-###"
              favaritePropertyName="isPreferred"
              :setFavoriteTooltipText="$t('contacts.setAsPreferredChannelType')"
              :unsetFavoriteTooltipText="
                $t('contacts.unsetAsPreferredChannelType')
              "
              :displayFavoriteButtonWhenNoEdit="true"
              :edit="true"
              :displayFavoriteButton="true"
              :label="$t('common.phone')"
              :additionalLabel="$t('common.additionalPhone')"
              :newItem="newPhoneChannel"
              :values="contact.phones"
              :error-messages="validationErrors.phones"
              @input="onPhoneChange"
              @setAsFavorite="
                (index) => onSetPhonePreferredChannel(index, true)
              "
              @unsetAsFavorite="
                (index) => onSetPhonePreferredChannel(index, false)
              "
            />
          </div>
        </div>

        <!-- WŁAŚCICIEL -->
        <div class="fields-section">
          <div class="field">
            <SelectWithAutocomplete
              solo
              item-value="id"
              :name="$t('contacts.owner')"
              :items="ownerItems"
              :item-text="
                (item) => `${item.surname} ${item.name} (${item.login})`
              "
              :value="contact.owner"
              @input="(value) => onValueChange('owner', value)"
            >
              <OwnerListItem slot="item" slot-scope="{ item }" :item="item" />
            </SelectWithAutocomplete>
          </div>
        </div>
      </div>
    </template>

    <!-- RIGHT SIDE -->
    <template #right>
      <v-card class="contact-details">
        <v-layout justify-space-around wrap>
          <v-flex xs11 mt-3>
            <v-flex justify-space-around justify-center wrap>
              <v-flex xs12>
                <div class="text-h6 textColor--text">
                  {{ $t("global.details") }}
                </div>
              </v-flex>
              <v-flex xs12 mt-3>
                <v-container grid-list-lg pa-1>
                  <ContactDetailsForm
                    :value="contact"
                    @input="onContactDetailsChange"
                  />
                </v-container>
              </v-flex>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-card>
    </template>
  </TwoSides>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";

import { mailChannel, smsChannel } from "@/models/channel.js";

import NewCrmCustomerService from "@/services/NewCrmCustomerService";

import ContactDetailsForm from "@/components/Contacts/ContactDetailsForm";
import CustomList from "@/components/Shared/CustomList";
import OwnerListItem from "@/components/ListItems/OwnerListItem";
import SelectWithAutocomplete from "@/components/Basic/SelectWithAutocomplete";
import TwoSides from "@/components/Shared/TwoSides";

export default {
  name: "ContactEditor",
  components: {
    ContactDetailsForm,
    CustomList,
    OwnerListItem,
    SelectWithAutocomplete,
    TwoSides,
  },
  props: {
    contact: { type: Object, default: () => {} },
    validationErrors: { type: Object },
  },
  created() {
    this.getTypes();
    this.getOwners();
  },
  data() {
    return {
      typeItems: [],
      ownerItems: [],
      newMailChannel: mailChannel,
      newPhoneChannel: smsChannel,
    };
  },
  methods: {
    onContactDetailsChange(newValue) {
      this.$emit("onChange", newValue);
    },
    onValueChange(propertyName, newValue) {
      this.$emit("onChange", { [propertyName]: newValue });
    },
    onEmailChange(newValue) {
      this.$emit("onChange", { emails: cloneDeep(newValue) });
    },
    onPhoneChange(newValue) {
      this.$emit("onChange", { phones: cloneDeep(newValue) });
    },
    getTypes() {
      NewCrmCustomerService.GetCrmTypes().then((response) => {
        this.typeItems = response.filter((item) => item.id !== 2);
      });
    },
    getOwners() {
      NewCrmCustomerService.GetOwners().then((response) => {
        this.ownerItems = response;
      });
    },
    onSetEmailPreferredChannel(preferredItemIndex, value) {
      const emails = [];
      let phones = [];

      this.contact.emails.forEach((email, index) => {
        if (preferredItemIndex === index) {
          emails.push({ ...email, isPreferred: value, isMain: value });
        } else {
          // Only one item is allow to be preffered, so others must be set to false
          emails.push({ ...email, isPreferred: false, isMain: false });
        }
      });

      if (value) {
        phones = this.contact.phones.map((phone) => {
          return {
            ...phone,
            isPreferred: false,
            isMain: false,
          };
        });

        this.$emit("onChange", { emails: emails, phones: phones });
      } else {
        this.$emit("onChange", { emails: emails });
      }
    },
    onSetPhonePreferredChannel(preferredItemIndex, value) {
      const phones = [];
      let emails = [];

      this.contact.phones.forEach((phone, index) => {
        if (preferredItemIndex === index) {
          phones.push({ ...phone, isPreferred: value, isMain: value });
        } else {
          // Only one item is allow to be preffered, so others must be set to false
          phones.push({ ...phone, isPreferred: false, isMain: false });
        }
      });

      if (value) {
        emails = this.contact.emails.map((item) => {
          return {
            ...item,
            isPreferred: false,
            isMain: false,
          };
        });

        this.$emit("onChange", { emails: emails, phones: phones });
      } else {
        this.$emit("onChange", { phones: phones });
      }
    },
  },
};
</script>

<style scope lang="scss">
.field-content {
  display: flex;
  flex-direction: column;

  .fields-section {
    display: flex;
    width: 100%;

    .field {
      width: 50%;
      padding: 10px;
    }
  }
}
</style>
