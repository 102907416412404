<template>
  <span>
    <v-layout wrap>
      <!-- <v-flex xs12 sm6 py-0>
        <BaseInput
          filled
          :name="textsContact.organization"
          darken
          :value="value.organization"
          @input="value => onValueChange('organization', value)"
        ></BaseInput>
      </v-flex> -->
      <v-flex xs12 sm6 py-0>
        <CategoryField
          class="pa-0"
          autocompleteField
          return-object
          filled
          :edit="true"
          :options="getDepartments"
          :label="textsContact.department"
          :value="value.department"
          @input="(value) => onValueChange('department', value)"
        ></CategoryField>
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <CategoryField
        class="xs12 sm6 py-0"
        autocompleteFieldAsync
        return-object
        filled
        :edit="true"
        :options="searchCity"
        :label="$t('address.city')"
        :value="value.city"
        @input="(value) => onValueChange('city', value)"
      >
        <CityListItem slot="item" slot-scope="{ item }" :item="item" />
      </CategoryField>
      <v-flex xs12 sm6 py-0>
        <BaseInput
          :name="textsAddress.postCode"
          filled
          darken
          :value="value.postCode"
          @input="(value) => onValueChange('postCode', value)"
          mask="##-###"
        ></BaseInput>
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <CategoryField
        class="xs12 sm6 py-0"
        autocompleteFieldAsync
        return-object
        filled
        :edit="true"
        :options="searchStreet"
        :label="$t('address.street')"
        :value="value.street"
        :disabled="!value.city || !value.city.id"
        @input="(value) => onValueChange('street', value)"
      />
      <v-flex xs12 sm6 py-0>
        <BaseInput
          :name="textsAddress.houseNo"
          darken
          filled
          :value="value.houseNo"
          @input="(value) => onValueChange('houseNo', value)"
        ></BaseInput>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <BaseTextarea
          :value="value.description"
          @input="(value) => onValueChange('description', value)"
          :name="$t('contacts.description')"
          filled
          flat
          darken
        ></BaseTextarea>
      </v-flex>
    </v-layout>
  </span>
</template>

<script>
import TerytService from "@/services/TerytService";
import ContactValidators from "@/mixins/validators/contact-validators.js";
import CategoryField from "@/components/Fields/CategoryField";
import CityListItem from "@/components/ListItems/CityListItem";
import CrmCustomersService from "@/services/CrmCustomersService";

export default {
  name: "ContactDetailsForm",
  mixins: [ContactValidators],
  props: {
    value: { type: Object },
  },
  components: {
    CategoryField,
    CityListItem,
  },
  data() {
    return {
      textsContact: this.$t("newContact"),
      textsAddress: this.$t("address"),
    };
  },
  methods: {
    searchCity(val) {
      return TerytService.GetCitiesAutocomplete(val);
    },
    searchStreet(val) {
      return TerytService.GetStreetsAutocomplete(val, this.value.city.id);
    },
    getDepartments() {
      return CrmCustomersService.GetDepartments();
    },
    onValueChange(propertyName, newValue) {
      const contactDetails = { ...this.value, [propertyName]: newValue };
      this.$emit("input", contactDetails);
    },
  },
  watch: {
    "value.city"(newValue, oldValue) {
      if (!newValue || (oldValue && oldValue.id !== newValue.id)) {
        const contactDetails = { ...this.value, street: undefined };
        this.$emit("input", contactDetails);
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-input__slot:before {
  border-color: #fbfbfb !important;
}
</style>
